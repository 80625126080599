@import "../../variables";


.advanced-rb-container{
  .advanced-rb-label{
    font-size: 0.80em;
    font-weight: bold;
    text-transform: uppercase;
    color: $advanced-input-label-color;
  }

  .advanced-rb-value{
    width: 100%;
    padding: 7px;
    border: none;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }

  .advanced-rb-label-wrapper{
    display: flex;
    justify-content: space-between;
  }

  margin-top: 10px;
  margin-bottom: 10px;

  label, input{
    cursor: pointer;
  }

  input {
    display: none;
  }

  label {
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;
  }

  label span {
    position: relative;
    line-height: 22px;
  }

  label span:before,
  label span:after {
    content: '';
  }



  label span:before {
    border: 1px solid #c07c00;
    border-radius: 6px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
  }

  label span:after {
    background: #c07c00;
    border-radius: 4px;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 2px;
    left: 4px;
    transition: 500ms;
    opacity: 0;
  }

  label input:disabled+span {
    color: gray;
    cursor: not-allowed;
  }

  label input:disabled+span:before {
    border-color: gray;
    background-color: lightgray;
    cursor: not-allowed;
  }
  label input:checked+span:after {
    opacity: 1;
  }

}

.advanced-rb-container.input-error{


  .advanced-rb-label{
    color: red;
  }

  .advanced-rb-error-msg{
    color:red;
    font-size: 0.9em;
  }
}
