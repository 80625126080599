.page-header{
  font-weight: bold;
  font-size: 1.5em;
  padding: 40px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

.base-flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.base-view-header{
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.5em;
  text-align: center;
}