.my-selected:not([disabled]) { 
  font-weight: bold; 
  border: 2px solid currentColor;
}
.my-selected:hover:not([disabled]) { 
  border-color: #8b0000;
  color: darkred;
}
.my-today { 
  font-weight: bold;
  font-size: 140%; 
  color: red;
}

.rdp{
  --rdp-background-color: #8b0000;
}


.date-picker{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #8b000079;


    .rdp-cell button:not([disabled]) {
      position: relative;
      display: block;
      transition: .3s;
    }

    .rdp-cell:not([disabled]):before button:not([disabled]) {
      content: '';
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      border-radius:50%;
      background: #d35400;
      transition: .5s;
      transform: scale(.9);
      z-index: -1;
    }

    .rdp-cell:hover:not([disabled]) button:not([disabled]) {
      background-color: #8b0000;
      color: white;
      color: #ffa502;
      box-shadow: 0 0 15px #d35400;
      text-shadow: 0 0 15px #d35400;
      font-weight: bold;
    }
    .rdp-cell:hover:not([disabled]):before button {
      transform: scale(1.2);
      box-shadow: 0 0 15px #d35400;
      filter: blur(3px);
    }

    .my-selected:not([disabled]) {
      box-shadow: 0 0 15px #d35400;
      text-shadow: 0 0 15px #d35400;
      color: #b6000079;
      font-weight: bold;
      border: 2px solid currentColor;
    }

}