@import "../../variables";
@import "BasePageView";

.map-page-view {
  background-color: white;
  height: 600px;

  width: 100%;
  color:black;

}


.map-page-header{
  @extend .base-view-header;
  color: $btn-bg-color;
}

.map-page-container{
  width: 100%;
  display: flex;
  justify-content: center;
}

.map-page-section{
  width: 40%;


}

.map-page-section.map-img{
  img{
    max-height: 300px;
    width: 100%;
    height: auto;
  }
  justify-content: center;
  align-content: center;
  padding: 20px;
  display: flex;
}

.map-description-header{
  font-weight: bold;
  font-size: 1.1em;
}

.map-description-section{

}


@media only screen and (max-width: 600px) {

  .map-page-section.map-img {
    padding: 0;
  }

  .map-page-container{
    flex-wrap: wrap;
  }

  .map-page-section{
    width: auto;
  }

}