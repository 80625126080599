.tab-switch{
  padding: 0 0 20px;
  z-index: 20;


  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;

    li {
      width: 135px;
      text-align: center;
      background: #fff0da;

      padding: 13px 15px;
      cursor: pointer;
      transition: all 0.5s ease;
      text-transform: uppercase;
      font-weight: bold;
      box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px 0 inset;
      border-radius:  10px;
      margin-left: 5px;
      margin-right: 5px;
    }



    li:hover{
      background: #be6800;
      color: #fff;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    }

    li.active{
      background: #790505;
      color: #fff;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    }
  }
}

@media only screen and (max-width: 600px) {
  .tab-switch{
    li{
      width: auto !important;
    }
  }

}