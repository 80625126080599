.modal-wrapper{
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.37);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal{
  background: white;

  height: 70%;
  position: relative;
  overflow: auto;

  img {
    height: 100%;
    min-height: 600px;
  }

  .modal-controls{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 2.5em;
    z-index: 1001;
    cursor: pointer;
  }
}