@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap');

body{
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

html{
  scroll-behavior: smooth;
}

.button {
  background-color: $yellow-color;
  /* other styles */
}


h1 {
  font-size: 3em;
}

.white {
  color: white;
}


.btn{
  color: white;
  background-color: $btn-bg-color;

  border: 0;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: 0.5s;
  border-radius: 10px;

  &:hover{
    background-color: $btn-bg-hover-color;
  }

  &:disabled{
    background-color: gray;
    cursor: not-allowed;
  }

  &:disabled:hover{
    background-color: gray;
  }

}

a.btn, a.btn-transparent {
  text-decoration: none;
}

.btn-transparent {
  color: orange;
  background: none;
  border: 1px solid orange;
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px;
  cursor: pointer;
  transition: 0.5s;
}

.btn-transparent:hover{
  color: $btn-bg-hover-color;
  border-color: $btn-bg-hover-color;
  box-shadow: 0 0 10px #d35400;
  text-shadow: 0 0 10px #d35400;
  //color: black;
}

.btn-transparent.darker{
  color: #a15800;
  border-color: #a15800;

  &:hover{
    color: darkorange;
  }
}

.btn-transparent.no-border{
  border: none;
  padding: 5px;

  &:hover{
    box-shadow: none;
  }
}

.btn-circle{
  @extend .btn;
  background: none;
  color:  $btn-bg-color;
  border-radius: 50px;
  height: 60px;
  width: 60px;
  font-size: 1.5em;
  border: 3px solid  $btn-bg-color;
}

.btn-circle:hover{
  background: none;
  color: orange;
  border: 3px solid orange;
}

.btn.slim{
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 0.75em;
}


.space-10{
  padding: 10px;
}