@import "../../variables";

.advanced-input-container{
  label{
    font-size: 0.80em;
    font-weight: bold;
    text-transform: uppercase;
    color: $advanced-input-label-color
  }

  input{
    width: 100%;
    padding: 7px;
    border: none;
    background: #fff0d5;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 0.90em;
  }

  input:focus{
    border: none;
  }

  margin-top: 10px;
  margin-bottom: 10px;


}

.advanced-input-helper{
  color: $advanced-input-helper-color;
  font-size: 0.90em;
}
.advanced-input-container.input-error{

  input{
    border: 1px solid red;
  }

  label{
    color:red;
  }

  .advanced-input-error-msg{
    color:red;
    font-size: 0.9em;
  }
}

.advanced-input-label-wrapper{
  display: flex;
  justify-content: space-between;
}
