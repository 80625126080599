@import "../../variables";

.tab-manager-header-container{
    display: flex;
    justify-content: center;
    .tab-header-content{
        font-size: 1.4em;
        padding: 10px;
        cursor: pointer;
        transition: 0.5s;
        color: white;

        &:hover{
            color: #ffc86c;
        }
    }
    .selected {
        color: orange;

        border-bottom: 1px solid orange;
        transition: 0.5s;
        &:hover{
            color: orange;
        }
    }
}

.tab-manager-content-container{
    color:white;
    .tab-content-bold {
        font-size: 1.1em;
        font-weight: bold;
    }
}

