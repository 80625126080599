@import "../../variables";


.product-container-wrapper{
  position: relative;
  width: 100%;
}

.product-container-wrapper-controls{
  position: absolute;
  z-index: 100;
  top: 48%;
}

.product-container-wrapper-controls.right{
  right: 80px;
}
.product-container-wrapper-controls.left{
  left: 80px;
}

.product-container {
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-flow: column;

  width: 70%;
  height: 1121px;
  overflow-x: hidden;
  margin: 0 auto;

}


.product-item-info-wrapper{
  height: 60px;
  display: grid;
  row-gap: 6px;
  align-content: center;

}

.product-detail-item-info-wrapper{
  @extend .product-item-info-wrapper;
  grid-template-columns: 1fr 1fr 1fr;
  width: 400px;
}

.product-item-info-custom-value{
  justify-self: center;
  display: flex;
}

.product-item-info-container{
  display: grid;
  justify-self: center;
  column-gap: 10px;
  font-size: 1.1em;

  .info-container-item{

  }
}

.product-item{
  position: relative;
  margin: 10px;
  transition: transform .2s;
  border: $container-border;
  max-width: 300px;
  box-shadow: $container-box-shadow;
  border-radius: 10px;
  height: 530px;

  &.special-offer{
    border-color: #790505;
    box-shadow:  rgba(121, 5, 5, 0.64) 0px 2px 8px 0px;

    .special-offer-item{
      color: white;
      background-color: #790505;
      position: absolute;
      padding: 5px;
      right: 10px;
      top: 10px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .product-item-image-container {
    width: 300px;
    height: 200px;
    position: relative;

    img {
      width: 300px;
      height: 200px;
      transition: transform .2s;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }


    .product-item-fader{
      width: 100%;
      height: 30px;
      background: linear-gradient(180deg, rgba(255,228,196,0) 0%, rgba(255,228,196,1) 100%);
      position: absolute;
      bottom: 0;

    }
  }

  .product-item-info-container {
    grid-template-columns: 1fr 1fr;
  }

  .product-item-container{
    color: $btn-bg-color;
    padding: 10px;

    .product-item-header{
      font-size: 1.1em;
      font-weight: 700;
      height: 40px;
    }


    .product-item-description-container{
      height: 120px;
      margin-top: 15px;
      margin-bottom: 10px;
    }

  }

  .buy-container{
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
  }


}

.product-detail-container{

  width: 70%;
  margin: 0 auto;

  .product-detail-header{
    padding: 20px;
    font-size: 1.3em;
    font-weight: 700;
    width: 80%;
    text-align: center;
    margin-bottom: 30px;
    color: $btn-bg-color;
  }

  .product-detail-controls{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .product-detail-info-wrapper{
    display: flex;
    position: relative;
    //justify-content: center;

    img{
      border-radius: 10px;
    }
  }

  .product-detail-absolute-controls{
    position: absolute;
    right: 60px;
  }

  .product-detail-description{
    padding: 60px;
    max-width: 1100px;
  }


  .product-item-info-container {
    grid-template-columns: 1fr 1fr auto;
  }

  .info-container-item{
    line-height: 40px;
    color: $btn-bg-color;
  }

  img{
    width: 250px;
  }
}

.product-container-simple{
  width: 600px;

  margin: 0 auto;

  .product-item-simple{
    background-color: #e7ceb1;
    padding: 5px;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    button{
      margin-left: 5px;
    }
  }

  .btn{
    font-size: 0.75em;
  }

  .product-item-simple-price-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 500px;
    margin-left: 100px;
  }

  .product-item-simple-price{
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

}


