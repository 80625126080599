@import "../../variables";
@import "BasePageView";

.shop-page-view {
  background-color: #f5c994;
  min-height: 1000px;
  width: 100%;
  position: relative;
  height: auto;
  
}

.page-header{
  display: flex;
  justify-content: center;
}

.page-content {
  @extend .base-flex-center;
}

@media only screen and (max-width: 992px) {
  .shop-container{
    overflow-x: scroll !important;
    touch-action: auto !important;
    width: 100% !important;
  }


}

@media only screen and (max-width: 600px) {

  .shop-page-view {
    min-height: 630px !important;
  }

  .shop-container {
    grid-template-rows: auto !important;
    overflow-x: scroll !important;
    touch-action: auto !important;
    height: 420px !important;
  }
}