@import "../../variables";

.person-selector{

  display: flex;


  .person-item{
    color: $btn-bg-color;
    margin: 10px;
    border: $container-border;
    width: 150px;
    box-shadow: $container-box-shadow;
    border-radius: 10px;

    .person-item-image{
      $person-img-size: 150px;

      width: $person-img-size;
      height: $person-img-size;
      position:relative;
      overflow:hidden;
      border-radius: 50%;

      img{
        position:absolute;
        height: auto;
        width: auto;
        max-width:100%;
        top:50%;
        transform:translateY(-50%);
      }


    }

    .person-item-detail {
      height: 18px;
      text-align: center;
    }

    .person-item-header{
      font-weight: bold;
      font-size: 1.2em;
      display: flex;
      justify-content: center;
    }

    .person-item-info {
      padding: 5px;
      display: flex;
      justify-content: center;
    }
  }

}


.person-selector-loading-center{
  display: flex;
  width: 100%;
  height: 300px;
  align-items: center;
  justify-content: center;
}
