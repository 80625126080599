@import "../../variables";
@import "BasePageView";

.page-view {
  background-color: bisque;
  height: calc(80vh - $nav-height);
  width: 100%;
  position: relative;
}
.intro-page-view{
  height: 80vh !important;
}

.container-center {
  @extend .base-flex-center;
}

.background-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.37), rgba(0, 0, 0, 0.37)) ,url('../../images/background.jpg');
  //background-image:  url('../../images/background.jpg') ;
  background-size: cover;
  width: 100%;
  position: absolute;
  z-index: 0;
  height: 100%;
}

.moving-background-image {
  animation: animate 15s ease-in-out infinite;
}


.page-intro-center-content {
  color: white;
  z-index: 1;

  .page-header-text {
    text-transform: uppercase;
    font-size: 4em;
    text-shadow: 0 0 5px #d35400;
    letter-spacing: -2px;
  }

  .page-header-subtext{
    font-size: 1.5em;
  }
  .page-header-subcontent{
    @extend .base-flex-center;


    .page-header-subtitle{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .page-header-controls{
    @extend .base-flex-center;
  }
}

.page-intro-news-container{
  position: absolute;
  bottom: 10%;
  left: 100px;
  color: white;

  .page-intro-news-item{
    padding: 15px;

    .page-intro-news-item-header{
      font-weight: bold;
      font-size: 1.7em;
      text-shadow: 0 0 5px black;
      color: orange;
    }

    .page-intro-news-item-content{
      font-size: 1.1em;
      max-width: 400px;
      text-shadow: 0 0 5px black;
    }
  }
}

.page-view-fader{
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
}

@keyframes animate {
  0%, 100% {
    background-position: left top;
  }
  25%{
    background-position: right bottom;
   }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }   
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {


  .page-intro-center-content {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }

  .page-header-text{
    text-align: center;
    font-size: 3.2em !important;
  }


  .page-intro-news-container{
    left: 0;
    bottom: 5px;
  }

  .page-intro-news-item{
    padding: 5px !important;
    font-size: 0.9em !important;
  }

}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {


}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}