@import "../../variables";

.footer-page-view {
  background-color: #500400;
  min-height: 400px;
  width: 100%;
  color: red($color: #c9bcbc);
  position: relative;
}

.footer-container{
  width: 650px;
}

.footer-content{
  color: white;

  display: flex;

  justify-content: space-between;

  .footer-contacts-item{

  }

  .footer-icons{
    font-size: 2em;
    line-height: 60px;

    .footer-icons-item{
      margin: 5px;
      cursor: pointer;
      color: white;
    }
  }
}

.footer-section-header{
  margin-top: 20px;
  font-weight: bold;
  color:white;
}

.footer-content.next-row{

}

.next-row.top-space{
  margin-top: 25px;
}

.container-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-contacts-item{
  a{
    color: white;
    text-decoration: none;
  }
  line-height: 30px;
}

.footer-logo{
  img {
    width: 150px;
  }
}

.disclaimer{
  color:white;
  font-size: 0.8em;
  position: absolute;
  bottom: 10px;
  right: 10px;
}


@media only screen and (max-width: 600px) {

  .footer-content{
    flex-wrap: wrap;
    justify-content: space-around;
  }

}