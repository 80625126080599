$photo-gallery-bg-color: #f5c994;

.photo-gallery{
  width: 100%;
  overflow: hidden;

 .header{
   font-size: 1.2em;
   font-weight: bold;
   text-align: center;
   width: 100%;
   display: block;
   text-transform: uppercase;
 }
}


.photo-gallery-single-row{
  padding: 10px;

  //background-color: $photo-gallery-bg-color;
  margin: 30px;
  border-radius: 10px;
  color: #500400;


  .photo-gallery-singe-row-content{

    display: flex;
    flex-wrap: wrap;
  }


  .photo-gallery-singe-row-content-item{
    margin: 30px;

    a {
      cursor: pointer;
    }
  }

  img{
    max-width: 200px;
    max-height: 200px;
  }
}

.photo-gallery-overview{
  display: flex;
  width: 200%;
  padding: 30px;

  margin: 20px;
  border-radius: 10px;
  animation: bannermove 20s linear infinite;

  .photo-gallery-overview-content{

    display: flex;

  }

  .photo-gallery-overview-content-item{
    margin: 20px;

  }

  .photo-gallery-overview-content-item:nth-child(odd){
    rotate: (4deg);
  }

  .photo-gallery-overview-content-item:nth-child(even){
    rotate: (-4deg);
  }


  img{
    max-width: 300px;
    max-height: 400px;
    border-radius: 10px;

  }



}

.photo-gallery-wrapper{
  margin-top: 30px;
  .header{
    color: bisque;
  }
}

@keyframes "bannermove" {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}


.photo-gallery-single-row {
  background: $photo-gallery-bg-color;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
  min-height: 220px;


  overflow:hidden;
  position: relative;

  &::before,
  &::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .photo-gallery-singe-row-content {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .slide {
    height: 100px;
    width: 250px;
  }
}


@media only screen and (max-width: 600px) {
  .photo-gallery-single-row{
    margin: 10px;
  }

}