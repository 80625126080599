.advanced-label-container{
  .advanced-label-label{
    font-size: 0.80em;
    font-weight: bold;
    text-transform: uppercase;
    color: #6b5035;
  }

  .advanced-label-value{
    width: 100%;
    padding: 7px;
    border: none;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }
  margin-top: 10px;
  margin-bottom: 10px;
}