@import "../../variables";

.time-slot-container{
    display: flex;
    width: 350px;
    flex-wrap: wrap;
    overflow: auto;

    .time-slot-item{
        margin: 3px;
        padding: 5px;
        border: $container-border;
        color: $btn-bg-color;
        cursor: pointer;
        font-size: 1.1em;
        border-radius: 10px;
        font-weight: 500;
        width: 90px;
        text-align: center;

    }

    .time-slot-item:hover{
        background-color: #8b0000;
        color: #ffdc9f;
        box-shadow: 0 0 5px #d35400;
    }

    .time-slot-loading-wrapper{
        width: 100px;
        height: 100px;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
    }
}

.booking-calendar-wrapper{
    display: flex;
    flex-wrap: wrap;
}


.booking-calendar-loading-center{
    display: flex;
    width: 100%;
    height: 300px;
    align-items: center;
    justify-content: center;
}
