@import "../../variables";
@import "../views/BasePageView";

.person-container{
  background-color: bisque;
  padding: 40px;
  margin: 30px;

  min-height: 450px;
  display: flex;
  justify-content: center;
  z-index: 5;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.34);


  .person-page-header{
    @extend .base-view-header;

    color: $btn-bg-color;
  }

  .person{

    position: relative;
    margin: 40px;
    padding: 10px;
    width: 180px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .person-info{
      display: block;
      padding: 10px
    }

    img {
      max-width: 150px;
      max-height: 150px;
    }

    .item-header{
      font-size: 1.3em;
      font-weight: 700;
      height: 50px;
      align-content: center;
      display: flex;
      //margin-bottom: 30px;
    }

  }
}

.person-page-wrapper{
}

.person-page-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.person-profile-img {
  position:relative;
  overflow:hidden;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  border: 3px orange solid;
}

.person-profile-img img {
  position:absolute;
  height: auto;
  width: auto;
  max-width:100%;
  top:50%;
  transform:translateY(-50%);
}

@media only screen and (max-width: 600px) {

  .person-container{
    width: auto;
    margin: 10px;
  }

}
