@import "../variables";

//.main-content{
//    margin-top: $nav-height;
//}

.btn {
    
}

.layout-content{
    position: relative;
}