@import "../../variables";

.booking-wizard-container{
    margin: 0 auto;
}



.wizard-updated-price{
    text-decoration: line-through;
}

$text-color: black;

.booking-wizard-steps-menu{
    justify-content: center;
    display: flex;

    ul {
        display: flex;
        list-style: none;
        padding: 0;
    }

    li{
        padding: 10px;
        color: gray;
        //border-bottom: 2px solid gray;
        transition: 0.6s;
        font-weight: bold;
        border-bottom: 2px solid rgba(121, 5, 5, 0);
    }

    li.booking-wizard-step-menu-active {
        font-weight: bold;
        color: $btn-bg-color;
        cursor: pointer;
        border-bottom: 2px solid $btn-bg-color;

    }

}


.booking-wizard-step-content{

    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    .booking-wizard-consumer-detail-form{
        display: flex;

        .voucher-error {
            color:red;
            font-weight: bold;
        }

        .voucher-success {
            color: green;
            font-weight: bold;
        }

        .voucher-code{
            font-size: 0.9em;
        }

        .voucher-value{
            margin-top: 10px;
        }

    }

    .booking-wizard-consumer-detail-info{
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 180px;
        margin-right: 20px;
    }
}
