.price-container{
  padding: 10px;
  background: #eec798;
  margin-left: 20px;
  margin-right: 30px;
  border-radius: 10px;
  transition: 0.5s all;
}

.price-container-item{
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 5px;
}

.price-container-item.price-bold{
  font-weight: bold;
}

.price-container.price-center{
  display: flex;
  justify-content: center;
}