@import "../variables";

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $nav-height;
  color: $nav-text-color;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  font-size: 1.2em;
  transition: 0.5s;

  .nav-section{
    margin: 15px;
  }

  img {
    max-height: $nav-height;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-right: 20px;
      
      &:last-child {
        margin-right: 0;
      }

      a {
        color: $nav-text-color;
        text-decoration: none;
        font-weight: bold;
        transition: all 0.3s ease;
        text-transform: uppercase;

        &:hover {
          color: lighten($nav-text-color, 20%);
        }
      }
    }
  }

  .menu-control-panel{
    position: absolute;
    right: 100px;
  }
}

nav.top{
  background: none;
  height: 140px;

  img {
    max-height: 120px;
  }
}

nav.scrolling{
  background-color: $nav-bg-color;
}

.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  position: relative;
}

.menu a {
  display: block;
  padding: 10px;
}

.menu a:hover {
  color: lighten($nav-text-color, 20%);
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  visibility: hidden;
  min-width: 200px;
  padding: 10px;
  background-color: $nav-bg-color;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  // animation: fade-in 0.3s ease-in;
  // animation: fade-out 0.3s ease-out;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
  opacity: 0;
  
}

.menu li:hover > .submenu {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
  // animation-name: fade-in-out;
  // animation-duration: 0.3s;
  // animation-timing-function: ease-in-out;
  // animation-fill-mode: forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
@media only screen and (max-width: 600px) {

  nav.scrolling {
    height: 80px;
    display: flex;
    justify-content: space-between;



    img{
      max-height: 70px;
    }
  }
}

.nav-menu-burger{
  font-size: 1.6em;
  margin-right: 10px;
}
.nav-menu-burger-section{
  position: fixed;
  right: 0;
  top: 80px;
  .menu{
    display: block;
    background-color: $nav-bg-color;

    li{
      padding: 20px;
    }
  }
}