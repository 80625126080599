.bucket{
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.bucket-items{
  width: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .bucket-item{
    width: 100%;
    background: rgba(211, 211, 211, 0.59);
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
  }
}

.bucket-item-cancel{
  button {
    font-size: 1.2em;
  }
}

.bucket-item-counter{


  input{
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 1em;
  }
}

.bucket-item-header{
  line-height: 30px;
}

.bucket-item-counter-controls{
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  .bucket-item-counter-plus{
    color: darkgreen;
  }

  .bucket-item-counter-minus{
    color: darkred;
  }
}

.bucket-item-price{
  margin-left: 15px;
}

.price-container{
  width: 300px;
}