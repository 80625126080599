$nav-bg-color: #333;
$nav-submenu-bg-color: #fff;
$nav-text-color: #d43a3a;

$yellow-color: #F7CA18;
$orange-color: orange;
// Colors

$bg-dark-color: #500400;

$btn-bg-color: #790505;
$btn-bg-hover-color: #be6800;
$advanced-input-label-color: #e67e00;
$advanced-input-helper-color: #864a02;
// Font sizes

// Sizes
$nav-height: 80px;


// Components
$container-border: 1px solid $orange-color;
$container-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;