@import "../../variables";
@import "BasePageView";

.news-view {
  min-height: 400px;
  color: white;
  background-color: $bg-dark-color;


  .news-container {
    padding: 50px 10%;
  }

  .news-item-header {
    font-weight: bold;
    font-size: 1.7em;
    text-shadow: 0 0 5px black;
    color: #f1d02a;
  }
}