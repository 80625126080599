@import "../../variables";
@import "BasePageView";

.info-page-view {
  background-color: #500400;
  min-height: 590px;
  width: 100%;
  color: red($color: #c9bcbc);

  justify-content: center;
  position: relative;
  overflow: hidden;

  .image-absolute{
    position: absolute;
  }

  .image-absolute-left{

    left: -200px;
    top: 70px;
    img{
      width: 600px;
    }
  }

  .image-absolute-right{
    right: -300px;
    top: -300px;
    height: 800px;
    width: 800px;

    img{
      position: unset;


    }
  }

  .info-page-content{
    padding: 20px;
    z-index: 10;
  }

  .info-page-header{
   color: white;

  }


  .tab-manager-content-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    p{
      width: 700px;
      height: 300px;
    }
  }
  .info-page-tab-content{
    li{
      margin-top: 10px;
    }
  }

}

@media only screen and (max-width: 1370px) {


  .info-page-view .image-absolute-right {
    right: -669px;
    top: -514px;
  }

  .info-page-view .image-absolute-left {
    left: -499px;
    top: 70px;
  }


}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 870px) {


  .info-page-view .image-absolute-right {
    right: -669px;
    top: -514px;
  }
}


@media only screen and (max-width: 600px) {



  .info-page-view .image-absolute-right {
    right: -669px;
    top: -514px;
  }

  .info-page-view .image-absolute-left {
    left: -550px;
    top: 70px;
  }

  .info-page-view .tab-manager-content-container{
    width: auto;
  }

}

