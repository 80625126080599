@import "../../variables";
@import "BasePageView";

.products-page-view {
  background-color: bisque;
  min-height: 1350px;
  width: 100%;
  position: relative;
  height: auto;
  
}

.page-header{
  display: flex;
  justify-content: center;
}

.page-content {
  @extend .base-flex-center;
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1370px) {
}

@media only screen and (max-width: 1200px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {


}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .product-container{
    overflow-x: scroll !important;
    touch-action: auto !important;
    width: 100% !important;
  }


}


@media only screen and (max-width: 600px) {

  .products-page-view{
    min-height: 700px !important;
  }

  .product-container{
    grid-template-rows: auto!important;
    overflow-x: scroll !important;
    touch-action: auto !important;
    height: 600px !important;
  }
  .product-container-simple{
    width: 450px !important;
    .product-item-simple-price-container{
      margin-left: 20px !important;
    }
  }

  .product-detail-description{
    padding: 50px 0 !important;
  }
  .product-detail-info-wrapper{
    flex-wrap: wrap;
  }

  .product-detail-container{
    width: 90% !important;
  }

  .person-selector{
    width: 360px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .booking-calendar-wrapper{
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom:50px;
  }

  .booking-wizard-consumer-detail-form{
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom:50px;
  }

  .booking-wizard-consumer-detail-content{
    margin-top: 20px;
  }
}

@media only screen and (max-width: 500px) {

}

@media only screen and (max-width: 500px) {

  .product-container-simple{
    max-width: 400px!important;
    width: auto !important;
    .product-item-simple-price-container{
      margin-left: 0 !important;
    }
  }
  .product-container-simple{
    font-size: 0.8em;
  }
  .product-detail-item-info-wrapper{
    width: auto !important;
  }
  .person-selector{
    width: auto !important;
  }
  .booking-calendar-wrapper{
    display: block !important;
  }

  .date-picker{
    padding: 0 !important;
  }

  .price-container{
    width: auto !important;
  }

  .advanced-rb-container{
    padding: 0 !important;
  }
}

@media only screen and (max-width: 350px) {

  .time-slot-container{
    width: auto !important;
  }
}