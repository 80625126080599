

.cancel-order-form-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.cancel-order-form-header{
  font-size: 1.3em;
  font-weight: bold;

  img{
    width: 250px;
  }
  margin-bottom: 20px;
}

.cancel-order-form-container{
  width: 300px;
}
.cancel-order-form-content{

}